// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-dlarodzicow-js": () => import("./../../../src/pages/dlarodzicow.js" /* webpackChunkName: "component---src-pages-dlarodzicow-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-grupa-1-js": () => import("./../../../src/pages/grupa1.js" /* webpackChunkName: "component---src-pages-grupa-1-js" */),
  "component---src-pages-grupa-2-js": () => import("./../../../src/pages/grupa2.js" /* webpackChunkName: "component---src-pages-grupa-2-js" */),
  "component---src-pages-grupa-3-js": () => import("./../../../src/pages/grupa3.js" /* webpackChunkName: "component---src-pages-grupa-3-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-onas-js": () => import("./../../../src/pages/onas.js" /* webpackChunkName: "component---src-pages-onas-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-rekrutacja-js": () => import("./../../../src/pages/rekrutacja.js" /* webpackChunkName: "component---src-pages-rekrutacja-js" */),
  "component---src-pages-statut-js": () => import("./../../../src/pages/statut.js" /* webpackChunkName: "component---src-pages-statut-js" */)
}

